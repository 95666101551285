<template>
  <div class="registration-moderator">
    <div class="registration-moderator__container-logo">
      <img
        src="@/assets/images/brains-logo.svg"
        alt="Brains logo"
        class="registration-moderator__logo"
      />
    </div>
    <div class="registration-moderator__content">
      <div class="registration-moderator__greeting">
        <h1 class="registration-moderator__tittle">Hello, moderator</h1>
        <h3 class="registration-moderator__sub-tittle">
          Please, Complete your registration
        </h3>
      </div>
      <div class="registration-moderator__form">
        <div>
          <label class="registration-moderator__input-label">
            Nick Name<br />
          </label>
          <input class="registration-moderator__input" id="nickName" />
        </div>
        <div>
          <label class="registration-moderator__input-label">
            Password<br />
          </label>
          <input
            class="registration-moderator__input"
            type="password"
            id="Password"
          />
        </div>
        <div>
          <label class="registration-moderator__input-label">
            Confirm Password<br />
          </label>
          <input
            class="registration-moderator__input"
            type="password"
            id="ConfirmPassword"
          />
        </div>
        <button class="registration-moderator__button">Create Profile</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationModerator',
};
</script>

<style lang="scss" scoped>
.registration-moderator {
  display: grid;
  justify-items: center;

  &__logo {
    height: 5rem;
    margin: 5rem;
  }
  &__tittle {
    font-size: 3rem;
    margin: 0rem;
  }
  &__sub-tittle {
    margin: 0rem 0rem 3rem 0rem; //top right bottom left
  }
  &__content {
    display: grid;
    justify-items: center;
    padding: 0 2rem;
  }
  &__button {
    @include primary-button();
    min-width: 30rem;
  }
  &__input-label {
    margin: 0 0 0 0.5rem;
  }
  &__input {
    @include input(100%);
    min-width: 30rem;
    margin-bottom: 1rem;
  }
}
</style>
